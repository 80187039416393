import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { User } from '../models/user';
import { SnackbarPannelClass } from 'src/app/shared/models/snackbar-panel-class';
import { SnackbarService } from '../../utils/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService, private router: Router,
    private snackbarService: SnackbarService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const currentUser = this.authService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.access_token;
    if (isLoggedIn) {
      //if user tires to login with expired token from local storage, don't send token to bff
      if (!req.url.endsWith("/account/auth") && !req.url.endsWith("/account/token/refresh") && !req.url.endsWith("/account/password/forgot"))
        req = this.addToken(req, currentUser.access_token)
    }
    return next.handle(req).pipe(catchError(error => {

      // We don't want to refresh token for some requests like login or refresh token itself
      // So we verify url and we throw an error if it's the case
      if (
        req.url.endsWith("/account/token/refresh") ||  //refresh
        req.url.endsWith("/account/auth")           //login 
      ) {
        // We do another check to see if refresh token failed
        // In this case we want to logout user and to redirect it to login page
        if (req.url.endsWith("/account/token/refresh")) {
          this.authService.logout();
          this.router.navigateByUrl("");
          return this.snackbarService.showSnackbar('Вашата сесија е истечена, најавете се повторно.', SnackbarPannelClass.Error)
        } else {
          return throwError(() => error);
        }
      } else if (error.status === 401) { //if token expired (or other 401), try again
        return this.handle401Error(req, next);
      } else {
        return throwError(() => error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    if (request.method == 'GET') {
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    } else {
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
        }
      });
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
      this.refreshTokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        switchMap((res: User) => {
          this.refreshTokenSubject.next(res.access_token);
          return next.handle(this.addToken(request, res.access_token));
        }));
  }
}
