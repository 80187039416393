<div class="space-top">
    <h3>{{'authorization.logInTitle' | translate}}</h3>
    <form (ngSubmit)="onSubmit()" #logInForm="ngForm" class="form-layout">
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'username' | translate}}</mat-label>
            <input matInput [(ngModel)]="model.username" name="username" [placeholder]="'username' | translate"
                class="form-control" #username="ngModel" pattern="^[a-zA-Z0-9-._]*$" (keypress)="usernameAllowedChars($event)" required [disabled]="loading">
            <mat-error *ngIf="username.invalid && (username.dirty || username.touched)">
                <span *ngIf="username.errors.required">{{'form.required' | translate}}</span>
                <span *ngIf="username.errors.pattern">{{'form.format' | translate}}</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'password' | translate}}</mat-label>
            <input matInput [placeholder]="'password' | translate" [type]="hidePass ? 'password' : 'text'" class="form-control"
                [(ngModel)]="model.password" #password="ngModel" name="password" required [disabled]="loading">
            <span mat-icon-button matSuffix (click)="hidePass = !hidePass" class="pointer">
                <mat-icon [matTooltip]="hidePass ? 'Прикажи ја лозинката' : 'Сокриј ја лозинката'">{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
            </span>
            <mat-error *ngIf="password.invalid && (password.dirty || password.touched)">
                <span *ngIf="password.errors.required">{{'form.required' | translate}}</span>
            </mat-error>
        </mat-form-field>

        <div class="flex-row">
            <a routerLink="/auth/forgot-password"> {{ 'authorization.forgotPassword' | translate }} </a>
        </div>

        <mat-divider class="divider"></mat-divider>
        <div>
            <button class="full-width" [disabled]="loading" type="submit" mat-raised-button
                color="primary">{{ 'authorization.logInButton' | translate }}</button>
            <mat-spinner *ngIf="loading" class="loading" diameter="30"></mat-spinner>
        </div>
    </form>
</div>