import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service'
import { User } from '../../auth/models/user';
import { Router } from '@angular/router';
import { Role } from '../../auth/models/role';
import { MatDialog } from '@angular/material/dialog';
import { TermsAndConditionsComponent } from 'src/app/shared/components/dialog/terms-and-conditions/terms-and-conditions.component';
import { WorkInstructionsComponent } from 'src/app/shared/components/dialog/work-instructions/work-instructions.component';


@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  role: Role
  Role = Role
  currentUser: User

  canViewUserManagement: boolean = false
  canViewOfferManagement: boolean = false
  canViewConfiguration: boolean = false
  canViewValidationModule: boolean = false
  canViewReport:boolean = false 


  show: boolean = false

  constructor(private authService: AuthService, 
              private router: Router,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue
    this.role = User.getRole(this.currentUser)
    this.canViewUserManagement = User.hasRole(this.currentUser, Role.TelekomAdminUser)
      || User.hasRole(this.currentUser, Role.PartnerUser) || User.hasRole(this.currentUser, Role.ITAdminUser)
    this.canViewOfferManagement = User.hasRole(this.currentUser, Role.TelekomAdminUser)
      || User.hasRole(this.currentUser, Role.PartnerUser) || User.hasRole(this.currentUser, Role.ITAdminUser)
    this.canViewConfiguration = User.hasRole(this.currentUser, Role.TelekomAdminUser) || User.hasRole(this.currentUser, Role.ITAdminUser)
    this.canViewReport = User.hasRole(this.currentUser, Role.TelekomAdminUser)
    || User.hasRole(this.currentUser, Role.PartnerUser) || User.hasRole(this.currentUser, Role.ITAdminUser)
    this.canViewValidationModule = User.hasRole(this.currentUser, Role.PosUser)


    this.currentUser.related_party_name = this.currentUser.related_party_name ? this.currentUser.related_party_name : 'Македонски Телеком'
  }

  termsAndConditions(){
    this.dialog.open(TermsAndConditionsComponent);
  }
  workInstructions(){
    this.dialog.open(WorkInstructionsComponent);
  }
  
  logOut() {
    this.authService.logout();
    this.router.navigateByUrl("");
  }
}
