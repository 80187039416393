import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-work-instructions',
  templateUrl: './work-instructions.component.html',
  styleUrls: ['./work-instructions.component.scss']
})
export class WorkInstructionsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<WorkInstructionsComponent>) { }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close()
  }

}
