import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './core/layouts/main-layout/main-layout.component';
import { LogInComponent } from './core/auth/log-in/log-in.component';
import { ForgotPasswordComponent } from './core/auth/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './core/auth/change-password/change-password.component';
import { AuthGuardService } from './core/auth/services/auth-guard.service';
import { AuthLayoutComponent } from './core/layouts/auth-layout/auth-layout.component';
import { Role } from './core/auth/models/role';

const routes: Routes = [
  { path: '', pathMatch: "full", redirectTo: "auth/log-in" },
  {
    path: 'auth', component: AuthLayoutComponent,
    children: [
      {
        path: 'log-in',
        component: LogInComponent
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      }
    ],
  },
  { 
    path: 'user-management', 
    component: MainLayoutComponent, 
    loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule),
    canActivate: [AuthGuardService],
    data: { roles: [Role.PartnerUser, Role.TelekomAdminUser, Role.ITAdminUser]}
  },
  { 
    path: 'offer-management', 
    component: MainLayoutComponent, 
    loadChildren: () => import('./offer-management/offer-management.module').then(m => m.OfferManagementModule),
    canActivate: [AuthGuardService],
    data: { roles: [Role.PartnerUser, Role.TelekomAdminUser, Role.ITAdminUser]}
  },
  { 
    path: 'reports', 
    component: MainLayoutComponent, 
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuardService],
    data: { roles: [Role.PartnerUser, Role.TelekomAdminUser, Role.ITAdminUser]}
  },
  { 
    path: 'configuration', 
    component: MainLayoutComponent, 
    loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule),
    canActivate: [AuthGuardService],
    data: { roles: [Role.TelekomAdminUser, Role.ITAdminUser]}
  },
  { 
    path: 'validation-code', 
    component: MainLayoutComponent, 
    loadChildren: () => import('./validation-code/validation.module').then(m => m.ValidationModule),
    canActivate: [AuthGuardService],
    data: { roles: [Role.TelekomAdminUser, Role.PosUser, Role.ITAdminUser]}
  },
  {
    path: 'application', 
    component: AuthLayoutComponent, 
    loadChildren: () => import('./application/application.module').then(a => a.ApplicationModule),
  },
  { path: '**', redirectTo: '/auth/log-in', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
