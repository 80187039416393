import { Injectable } from '@angular/core';
import { of, BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { map, tap } from 'rxjs/operators';
import { Guid } from 'guid-typescript';
import { EnvService } from './environment/env.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  
  constructor(private http: HttpClient, private env:EnvService) {
    this.currentUserSubject = 
      new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser'))); //set from local/session storage
    this.currentUser = this.currentUserSubject.asObservable();
   }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  logIn(username: string, password: string) {
    return this.http.post<User>(`${this.env.bffUrl}/account/auth`, { username: username, password: password }, { observe: 'response' })
      .pipe(map(resp => {
        var user = resp.body
        this.storeTokens(user)
        //set session id in localstorage (it is send to bff on every request for audit actions)
        localStorage.setItem('session-id', Guid.create().toString())
        return resp;
      }))
  }

  changePassword(password: string, isFirstTimeLogin: boolean) {
    return this.http.post<any>(`${this.env.bffUrl}/account/password/initial`, {
      password: password, IsFirstTimeLogin: isFirstTimeLogin
    }, { observe: 'response' })
      .pipe(map(resp => {
        var user = resp.body
        this.storeTokens(user)
        return resp;
      }))
  }

  storeTokens(user: User) {
    if (user && user.access_token) {
      //store in local/session storage
      localStorage.setItem('currentUser', JSON.stringify(user));
      this.currentUserSubject.next(user);
    }
  }

  refreshToken() {
    return this.http.post<any>(this.env.bffUrl + '/account/token/refresh', {  
      'refreshToken': this.currentUserValue.refresh_token 
    }).pipe(
      tap(resp => { 
        this.storeTokens(resp)
      })
    )
  }

  forgotPassword(username: string, phoneNumber: string) {
    return this.http.post<any>(this.env.bffUrl + '/account/password/forgot', { username: username, phoneNumber: phoneNumber })
  }

  logout(){
    this.http.get(this.env.bffUrl + '/account/logout').subscribe()
    //clear local/session storage
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
