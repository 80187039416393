import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { InfoDialogComponent } from './components/dialog/info-dialog/info-dialog.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MustMatchDirective } from './directives/must-match.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS,MatMomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginatorIntl} from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ConfirmationDialogComponent } from './components/dialog/confirmation-dialog/confirmation-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { TermsAndConditionsComponent } from './components/dialog/terms-and-conditions/terms-and-conditions.component';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatChipsModule } from '@angular/material/chips';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PaginatorIntlService } from './paginator-intl/paginator-intl';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { WorkInstructionsComponent } from './components/dialog/work-instructions/work-instructions.component';
import { MatStepperModule} from '@angular/material/stepper'

@NgModule({
  declarations: [InfoDialogComponent, MustMatchDirective, ConfirmationDialogComponent, TermsAndConditionsComponent, WorkInstructionsComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatTooltipModule,
    TranslateModule,
    MatDatepickerModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatCardModule,
    MatRadioModule,
    MatCheckboxModule,
    NgxExtendedPdfViewerModule,
    AngularEditorModule,
    MatChipsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    DragDropModule,
    ImageCropperModule,
    MatSortModule,
    MatSlideToggleModule,
    MatStepperModule
  ],
  exports: [
    TranslateModule,
    MatMenuModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDividerModule,
    MustMatchDirective,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatCardModule,
    MatRadioModule,
    MatCheckboxModule,
    NgxExtendedPdfViewerModule,
    AngularEditorModule,
    MatChipsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    DragDropModule,
    ImageCropperModule,
    MatSortModule,
    MatSlideToggleModule,
    MatStepperModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'mk' },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new PaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue:{ color: 'primary' }}
  ],
})
export class SharedModule { }
