import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import packageJson from '../../../../../package.json';
import { Guid } from 'guid-typescript'

@Injectable({
  providedIn: 'root'
})
export class HeadersInterceptorService implements HttpInterceptor {

  constructor() { }

  public version: string = packageJson.version;
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      headers: req.headers.set('x-client-id', 'MagentaClub_WEB')
      .set('x-client-version', this.version)
      .set('x-request-session-id', localStorage.getItem('session-id') || '')
      .set('x-request-tracking-id', Guid.create().toString())
      .set('x-forwarded-proto', location.protocol.slice(0, -1))
    })

    return next.handle(req)
  }
}
