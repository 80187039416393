import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { User } from '../models/user';
import { Role } from '../models/role';

/**
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      if(route.url[0].path == 'auth') {
        this.router.navigate(['/user-management/']);
        return false;
      }
      if(User.hasRole(currentUser, Role.Dummy)) {
        this.router.navigate(['auth', 'change-password']);
        return false;
      }
      
      // check if route is restricted by role, and check if user has atleast one of the required roles
      if (route.data.roles && !route.data.roles.find(r => User.hasRole(currentUser, r))) {
        // role not authorised so redirect to home page
        this.router.navigate(['/']);
        return false;
      }
      return true;
    }

    // User is not authenticated
    this.router.navigate(['/auth/log-in']);
    return false;
  }
}
