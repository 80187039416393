<div class="space-top">
    <h1 class="title">{{'authorization.forgotPasswordTitle' | translate}}</h1>
    <form [formGroup]="forgotPassForm" class="form-layout">
        <mat-form-field class="full-width">
            <input matInput [placeholder]="'username' | translate" type="text" class="form-control"
                formControlName="username" (keypress)="usernameAllowedChars($event)" required>
            <mat-error
                *ngIf="forgotPassForm.controls.username.invalid && (forgotPassForm.controls.username.dirty || forgotPassForm.controls.username.touched)">
                <span *ngIf="forgotPassForm.controls.username.errors.required">{{'form.required' | translate}}</span>
            </mat-error>
            <mat-error
                *ngIf="forgotPassForm.controls.username.errors && forgotPassForm.controls.username.errors.pattern">
                {{'form.format' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
            <input matInput [placeholder]="'phone' | translate" type="text" (keypress)="numberOnly($event)"
                formControlName="contactPhoneNumber" required>
            <span matPrefix>389&nbsp;</span>
            <mat-error
                *ngIf="forgotPassForm.controls.contactPhoneNumber.errors && forgotPassForm.controls.contactPhoneNumber.errors.required">
                {{'form.required' | translate}}</mat-error>
            <mat-error
                *ngIf="forgotPassForm.controls.contactPhoneNumber.errors && forgotPassForm.controls.contactPhoneNumber.errors.pattern">
                {{'form.format' | translate}}</mat-error>
        </mat-form-field>
        <br><br>

        <div>
            <button [disabled]="!forgotPassForm.valid || loading" type="submit" (click)="onSubmit()" mat-raised-button
                color="primary" id="submitBtn">{{ 'sendButton' | translate }}</button>
            <mat-spinner *ngIf="loading" class="loading" diameter="30"></mat-spinner>
        </div>
    </form>
</div>