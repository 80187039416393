import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarPannelClass } from 'src/app/shared/models/snackbar-panel-class';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private translate: TranslateService, private snackbar: MatSnackBar) { }  

  async showSnackbar(message: string, pannelClass: SnackbarPannelClass) {
    var [localizedMessage, close] = await Promise.all([
      this.translate.get(message).toPromise(),
      this.translate.get("close").toPromise()
    ])

    this.snackbar.open(localizedMessage, close, {
      duration: 5000,
      panelClass: pannelClass
    })
  }
}
