<div class="space-top">
    <h1 class="title">{{ isFirstTimeLogin ? ('authorization.firstTimeLoginChangePasswordTitle' | translate) : ('authorization.changePasswordTitle' | translate)}}</h1>
    <br>
    <form (ngSubmit)="onSubmit()" #f="ngForm"  class="form-layout" [mustMatch]="['password', 'confirmPassword']">    
        <mat-form-field class="full-width multi-line-error">
            <input matInput [placeholder]="'password' | translate" [type]="hidePass ? 'password' : 'text'" class="form-control"
                [(ngModel)]="model.password" #password="ngModel" name="password" minlength="12" maxlength="50" 
                pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~#^@$!%*?&<>])[A-Za-z\d~#^@$!%*?&<>]{12,50}$' required
                [disabled]="loading">
            <span mat-icon-button matSuffix (click)="hidePass = !hidePass" class="pointer">
                <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
            </span>
            <mat-error *ngIf="password.invalid && (password.dirty || password.touched)">
                <span *ngIf="password.errors.required">{{'form.required' | translate}}</span>
                <span *ngIf="password.errors.minlength || password.errors.pattern">{{'form.passwordPattern' | translate}}</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
            <input matInput [placeholder]="'authorization.confirmPassword' | translate" [type]="hideConfPass ? 'password' : 'text'" class="form-control"
                [(ngModel)]="model.confirmPassword" #confirmPassword="ngModel" name="confirmPassword" minlength="12"
                maxlength="50" required [disabled]="loading">
            <span mat-icon-button matSuffix (click)="hideConfPass = !hideConfPass" class="pointer">
                <mat-icon>{{hideConfPass ? 'visibility_off' : 'visibility'}}</mat-icon>
            </span>
            <mat-error *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
                <span *ngIf="confirmPassword.errors.required">{{'form.required' | translate}}</span>
                <span *ngIf="confirmPassword.errors.minlength || confirmPassword.errors.maxlength">{{'form.format' | translate}}</span>
                <span *ngIf="confirmPassword.errors.mustMatch && (!confirmPassword.errors.minlength && !confirmPassword.errors.maxlength)">{{'form.passwordsNoMatch' | translate}}</span>
            </mat-error>
        </mat-form-field>
        <section *ngIf="isFirstTimeLogin && !termsAndConditionsAccepted">
            <mat-checkbox color="primary" class="form-control" [(ngModel)]="model.consent" #consent="ngModel" name="consent"
                required> Се согласувам со
                <a (click)="termsAndConditions()">Условите за соработка</a>
            </mat-checkbox>
        </section>
        <mat-divider class="divider"></mat-divider>
        <div [matTooltip]="isFirstTimeLogin && !termsAndConditionsAccepted ? 'Мора да ги прифатите условите за соработка за да продолжите' : ''">
            <button [disabled]="!f.form.valid || loading" type="submit" mat-raised-button
                color="primary">{{ 'save' | translate }}</button>
            <mat-spinner *ngIf="loading" class="loading" diameter="30"></mat-spinner>
        </div>
    </form>
</div>