<header>
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <div class="layout header-layout">
                <img class="logo" src="assets/images/logo-new.svg" alt="logo">
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-toolbar class="subheader">
        <div class="layout subheader-layout">           
            <span class="app-title">{{ 'appName' | translate }}</span>   
            <span class="spacer"></span>
        </div>
    </mat-toolbar>
</header>
