import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EnvService } from 'src/app/core/auth/services/environment/env.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  docUrl = this.env.bffUrl + '/file/download/Agreement.pdf';

  constructor(
    private env: EnvService, public dialogRef: MatDialogRef<TermsAndConditionsComponent>) { 
  }

  ngOnInit(): void {

  }

  close(){
    this.dialogRef.close()
  }

}
