import { PosUser } from './../../../user-management/models/pos-users-table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AllowedCharService } from '../../utils/allowed-char.service';
import { InfoDialogComponent } from 'src/app/shared/components/dialog/info-dialog/info-dialog.component';
import { AuthService } from '../services/auth.service';
import { SnackbarService } from '../../utils/snackbar.service';
import { NgForm } from '@angular/forms';
import { ErrorMessage } from 'src/app/core/auth/models/error-msg-response';
import { SnackbarPannelClass } from 'src/app/shared/models/snackbar-panel-class';
import { LogInError } from '../models/login-error-msg';


@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss']
})
export class LogInComponent implements OnInit {

  model = {
    username: '',
    password: ''
  };

  @ViewChild('logInForm') public loginFrm: NgForm;
  
  loading = false;
  hidePass = true
  hideConfPass = true

  constructor(private dialog: MatDialog, private translate: TranslateService, private authService: AuthService,
    private router: Router, private allowedCharService: AllowedCharService, 
    private snackbarService: SnackbarService
  ) {

  }

  ngOnInit() {
  }

  openInfoDialog(): void {
    this.translate.get('authorization.logInScreenInfo').toPromise()
      .then((value) => {
        this.dialog.open(InfoDialogComponent, {
          width: '300px',
          data: { body: value }
        })
      })
  }

  usernameAllowedChars(event){
    return this.allowedCharService.usernameAllowedChars(event)    
  }

  onSubmit() {
    if(this.loginFrm.form.invalid) {
      return
    }
    this.loading = true;
    let username: string = this.model.username + '@magentaclub.telekom.mk'
    this.authService.logIn(username, this.model.password)
      .subscribe({
        next: (res) => {
          this.loading = false;
          if(res.body.role == 'http://magentaclub/pos-user') {
            this.router.navigate(['/validation-code'])
        } else {
          this.router.navigate(['/user-management/'])
        }
        },
        error:(error: HttpErrorResponse) => {
          this.loading = false;
          console.log(error);
          try {
            var errorMessage: ErrorMessage = error.error;
            console.log(errorMessage);
            switch (errorMessage.detail) {
              case LogInError.AccountLocked:
                this.snackbarService.showSnackbar("authorization.accountLocked", SnackbarPannelClass.Error)
                  .then();
                break;
              case LogInError.AuthenticationError:
                this.snackbarService.showSnackbar("authorization.errorBadCredentials", SnackbarPannelClass.Error)
                  .then();
                break;
              case LogInError.AccountBarred:
                this.snackbarService.showSnackbar("authorization.accountBarred", SnackbarPannelClass.Error)
                  .then();
                break;
              case LogInError.PasswordExpired:
                this.snackbarService.showSnackbar("authorization.passwordExpired", SnackbarPannelClass.Error)
                  .then();
                break;
              case LogInError. ContractExpired:
                this.snackbarService.showSnackbar("authorization.contractExpired", SnackbarPannelClass.Error)
                  .then();
                break;
              default:
                this.snackbarService.showSnackbar('errorTryAgain', SnackbarPannelClass.Error)
                  .then()
                break;
            }
          }
          catch (err) {
            console.log(err);
            this.snackbarService.showSnackbar('errorTryAgain', SnackbarPannelClass.Error)
              .then()
          }
        }
      })
  }
}