<mat-drawer-container >
    <mat-drawer (mouseleave)="show=false" #drawer mode="side" disableClose="true" opened="true" class="sidebar">
        <div class="sidebar-content">
            <mat-icon class="hamburger" (click)="show = show === true ? false : true">menu</mat-icon>
            <div class="info">
                <div class="flex-container">
                    <mat-icon class="material-icons info-icon" [matTooltip]="currentUser.full_name" matTooltipPosition="right">account_circle</mat-icon>
                    <div *ngIf="show">
                        <h3>{{currentUser.full_name}}</h3>
                        <h5>{{currentUser.username.replace('@magentaclub.telekom.mk','')}}</h5>
                    </div>
                </div>
                <div class="flex-container">
                    <mat-icon class="material-icons info-icon" [matTooltip]="currentUser.related_party_name" matTooltipPosition="right">business</mat-icon>
                    <div *ngIf="show">
                        <h3>{{currentUser.related_party_name}}</h3>
                        <h5>Компанија</h5>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <mat-nav-list class="link-list">
                <a mat-list-item routerLink="/user-management/telekom-admin" *ngIf="canViewUserManagement">
                    <mat-icon class="material-icons menu-icon" matTooltip="Управување со корисници" matTooltipPosition="right">people</mat-icon>
                    <h4 *ngIf="show">Управување со корисници</h4>
                </a>
                <a mat-list-item routerLink="/offer-management/offers" *ngIf="canViewOfferManagement">
                    <mat-icon class="material-icons menu-icon" matTooltip="Понуди" matTooltipPosition="right">local_offer</mat-icon>
                    <h4 *ngIf="show">Понуди</h4>
                </a>
                <a mat-list-item routerLink="/reports" *ngIf="canViewReport">
                    <mat-icon class="material-icons menu-icon" matTooltip="Извештаи" matTooltipPosition="right">bar_chart</mat-icon>
                    <h4 *ngIf="show">Извештаи</h4>
                </a>
                <a mat-list-item routerLink="/configuration/categories" *ngIf="canViewConfiguration">                                                        
                    <mat-icon class="material-icons menu-icon" matTooltip="Конфигуратори" matTooltipPosition="right">settings</mat-icon>
                    <h4 *ngIf="show">Конфигуратори</h4>
                </a>
                <a mat-list-item (click)="termsAndConditions()">
                    <mat-icon class="material-icons menu-icon" matTooltip="Услови за соработка" matTooltipPosition="right">insert_drive_file</mat-icon>
                    <h4 *ngIf="show">Услови за соработка</h4>
                </a>
                <a mat-list-item (click)="workInstructions()">
                    <mat-icon class="material-icons menu-icon" matTooltip="Упатство за користење на платформа" matTooltipPosition="right">library_books</mat-icon>
                    <h4 *ngIf="show">Упатство за користење на платформа</h4>
                </a>
                <a mat-list-item routerLink="/validation-code" *ngIf="canViewValidationModule">
                    <mat-icon class="material-icons menu-icon" matTooltip="Валидација на код" matTooltipPosition="right">beenhere</mat-icon>
                    <h4 *ngIf="show">Валидација на код</h4>
                </a>
            </mat-nav-list>
            <button mat-icon-button color="primary" (click)="logOut()">
                <mat-icon class="material-icons menu-icon" [matTooltip]="'authorization.logOutButton' | translate" matTooltipPosition="right">logout</mat-icon>
                <span class="logout-button" *ngIf="show">{{ 'authorization.logOutButton' | translate }}</span>
              </button>
            <br>  
        </div>
    </mat-drawer>
    <mat-drawer-content>
        <div class="wrapper">
            <app-header></app-header>
            <main>
                <router-outlet></router-outlet>
            </main>
            <app-footer></app-footer>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
