import { SnackbarService } from '../../utils/snackbar.service'
import { Component, OnInit } from '@angular/core'
import { AuthService } from '../services/auth.service'
import { Router } from '@angular/router'
import { SnackbarPannelClass } from 'src/app/shared/models/snackbar-panel-class'
import { HttpErrorResponse } from '@angular/common/http'
import { MatDialog } from '@angular/material/dialog'
import { TermsAndConditionsComponent } from 'src/app/shared/components/dialog/terms-and-conditions/terms-and-conditions.component'

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  loading = false
  hidePass = true
  hideConfPass = true
  model: any = {}
  isFirstTimeLogin: boolean;
  termsAndConditionsAccepted: boolean

  constructor(private authService: AuthService,
    private router: Router,
    private snackbarService: SnackbarService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.isFirstTimeLogin = this.authService.currentUserValue.IsFirstTimeLogin
    this.termsAndConditionsAccepted = this.authService.currentUserValue.TermsAndConditionsAccepted
  }

  termsAndConditions() {
    this.dialog.open(TermsAndConditionsComponent);
  }

  onSubmit() {
    this.loading = true
    this.authService.changePassword(this.model.password, this.isFirstTimeLogin)
      .subscribe({
        next: () => {
          this.loading = false
          this.router.navigate(['/auth/log-in']);
          this.snackbarService.showSnackbar("authorization.successPasswordChange", SnackbarPannelClass.Success)
        },
        error: (error: HttpErrorResponse) => {
          this.loading = false
          console.error(error)
          this.snackbarService.showSnackbar("error", SnackbarPannelClass.Error)
        }
      }
      )
  }
}
