import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { LogInComponent } from './auth/log-in/log-in.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { AuthInterceptorService } from './auth/services/auth-interceptor.service';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { HeadersInterceptorService } from './auth/services/headers-interceptor.service';


@NgModule({
    declarations: [HeaderComponent, FooterComponent, MainLayoutComponent, LogInComponent, ForgotPasswordComponent, ChangePasswordComponent, AuthLayoutComponent],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        HttpClientModule,
        MatSidenavModule,
        MatListModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptorService, multi: true },
    ]
})
export class CoreModule { }

