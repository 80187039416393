import { Role } from './role';
import { Claim } from './claim';

export class User {
    access_token?: string;
    refresh_token?: string;
    claims: Claim[];
    role: Role
    full_name: string
    related_party_id?: number
    username?: string
    user_id?: number
    parent_organization_contact?: string
    parent_organization_name?: string
    related_party_name?: string
    IsFirstTimeLogin?: boolean
    TermsAndConditionsAccepted?: boolean


    constructor() {
        this.claims = [];
        this.role = undefined
        this.full_name = undefined
        this.access_token = undefined;
        this.refresh_token = undefined;
        this.related_party_id = undefined
        this.username = undefined
        this.user_id = undefined
        this.parent_organization_contact = undefined
        this.parent_organization_name = undefined
        this.related_party_name = undefined
        this.IsFirstTimeLogin = undefined
        this.TermsAndConditionsAccepted = undefined
    }

    static createUser(access_token: string, refresh_token: string, claims: Claim[], related_party_id: number, 
        username: string, user_id: number): User {
        var user = new User();
        user.access_token = access_token;
        user.refresh_token = refresh_token;
        user.claims = [...claims];
        user.related_party_id = related_party_id
        user.username = username
        user.user_id = user_id
        return user;
    }

    static hasRole(user: User, role: Role) {
        //return Boolean(user && user.claims && user.claims.find(el => el.value == role));
        return Boolean(user && user.role == role)
    }

    static getRole(user: User): Role | undefined {
        // var claim = user.claims.find(el => el.type != Role.Dummy);
        // if (claim)
        //     return claim.value;
        // else
        //     return undefined;
        var role = user.role
        if(role != Role.Dummy) {
            return role
        } else {
            return undefined
        }
    }
}